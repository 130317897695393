<template>
  <v-container fluid class="pa-0">
    <v-row>
      <img src="../assets/applications.png" height="100%" width="100%">
    </v-row>
    <v-row>
      <v-col
        cols="3"
        class="d-none d-md-block"
      >
        <ApplicationsMenu class="mx-4 my-4"></ApplicationsMenu>
        <ContactUs class="mx-4 mb-4"></ContactUs>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="pl-4 pr-6"
      >
        <h3 class="blue--text text--darken-2 py-2">{{ applications[currentId - 1].title }}</h3>
        <v-divider></v-divider>
        <v-img :src="applications[currentId - 1].img"></v-img>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-btn
            color="blue-grey lighten-3"
            class="ma-2 white--text"
            @click="goBack"
          >
            Go Back
            <v-icon right dark>mdi-undo</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApplicationsMenu from './ApplicationsMenu'
import ContactUs from './ContactUs'

export default {
  components: {
    ApplicationsMenu,
    ContactUs
  },

  data: () => ({
    currentId: null,
    applications: [
      {
        title: "Electronic Products",
        img: require('../assets/application1.png')
      },
      {
        title: "Daily Necessities",
        img: require('../assets/application2.png')
      },
      {
        title: "Building Materials",
        img: require('../assets/application3.png')
      },
      {
        title: "Machinery Parts",
        img: require('../assets/application4.png')
      },
      {
        title: "Industrial Products",
        img: require('../assets/application5.png')
      },
      {
        title: "Automotive Products",
        img: require('../assets/application6.png')
      },
    ],
  }),

  created () {
    this.fetchData()
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    fetchData () {
      this.currentId = this.$route.params.id
    },
    goBack () {
      this.$router.back()
    }
  },
}
</script>

